export function scrollToElement(element) {
  if (element) {
    const rect = element.getBoundingClientRect();
    // check if the header is visible else scroll
    if (!(
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth))
    ) {
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }
}

import { throttle } from "./throttle";

let innerHeight = window.innerHeight;

window.addEventListener("resize", throttle(onResize));

export function getWindowInnerHeight() {
  return innerHeight;
}

function onResize() {
  innerHeight = window.innerHeight;
}

import { dataLayerPush } from "@dn/scripts/helpers/dataLayerPush";

const buildContentAccessData = (paywallType) => {
  if (paywallType === "Frequence Locked") {
    return {
      contentAccessStatus: "limited",
      contentLockType: "frequency lock",
    };
  }

  return { contentAccessStatus: "locked" };
};

const buildTargetAudience = (segment) => {
  if (typeof segment === "object") {
    return segment?.map((seg) => ({ id: seg }));
  }

  return [];
};

const buildBaseEcommerceEvent = (trackingParams, isReceipt = false, isPaywall = false) => {
  const eventData = {
    flow: {
      name: isPaywall ? "article paywall" : "offer page flow",
    },
    ...(isPaywall && { eventParams: buildContentAccessData(trackingParams.paywallType) }),
    ecommerce: {
      campaign: {
        name: trackingParams.activityCode,
        activityCode: trackingParams.activityCode,
        targetAudience: buildTargetAudience(trackingParams?.segment),
      },
      currency: "sek",
      products: [{
        name: trackingParams.product,
        price: trackingParams.productPrice ? +(trackingParams.productPrice).toFixed(2) : undefined,
        ...(isReceipt && { quantity: 1 }),
        market: "se",
        offerCode: trackingParams.offerCode,
      }],
    },
    engagement: {
      type: "impression",
      component: {
        name: trackingParams.contentfulType ?? trackingParams.position,
        id: trackingParams.componentId,
      },
    }
  };

  return eventData;
};

const checkoutImpression = (containerEl, isPaywall = false) => {
  const trackingParams = JSON.parse(containerEl.getAttribute("data-tracking-params"));
  const baseEventData = buildBaseEcommerceEvent(trackingParams, false, isPaywall);
  const eventData = { event: "checkout impression", ...baseEventData };

  dataLayerPush(eventData);
};

const checkoutStart = (containerEl, isPaywall = false) => {
  const trackingParams = JSON.parse(containerEl.getAttribute("data-tracking-params"));
  const baseEventData = buildBaseEcommerceEvent(trackingParams, false, isPaywall);
  const eventData = {
    event: "checkout start",
    ...baseEventData,
    engagement: {
      ...baseEventData.engagement,
      type: "click",
    }
  };

  dataLayerPush(eventData);
};

const paywallReceipt = (containerEl, isPaywall = false) => {
  const trackingParams = JSON.parse(containerEl.getAttribute("data-tracking-params"));

  const baseEventData = buildBaseEcommerceEvent(trackingParams, true, isPaywall);
  const eventData = {
    event: "receipt",
    ...baseEventData,
    ecommerce: {
      ...baseEventData.ecommerce,
      order: [{
        id: undefined,
        value: trackingParams.productPrice ? +(trackingParams.productPrice).toFixed(2) : undefined,
        payment: {
          method: undefined,
          system: undefined,
        }
      }],
    },
  };

  dataLayerPush(eventData);
};

export {
  checkoutImpression,
  paywallReceipt,
  checkoutStart
};

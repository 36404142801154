import { subscribe, publish } from "@dn/scripts/helpers/pubsub";
import {
  showErrorMessage,
  enableSubmitButton,
  initFormValidation
} from "@dn/components/form/form";
import { scrollToElement } from "@dn/scripts/helpers/scrollToElement";
import { getRefs } from "@dn/components/helpers/getRefs";

export function GetPrintOrderDetails(container) {
  const getPrintOrderDetailsForm = container.querySelector("form");
  if (getPrintOrderDetailsForm) {
    initFormValidation(getPrintOrderDetailsForm, "getPrintOrderDetails", handleFormData);
  }

  subscribe("getPrintOrderDetails:formSubmitted", () => {
    const messageContainer = container.querySelector(".form__message");
    messageContainer.classList.add("hidden");
  });

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.errorHtml);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      publish("printCheckout:showStep2", data);
      enableSubmitButton(form);
      return;
    }
  }
}

export function ValidatePrintUserInfo(container) {
  const {
    invoiceAddressCheckbox,
    invoiceAddressCheckboxValue,
    invoiceFieldset
  } = getRefs(container);

  const validatePrintUserInfoForm = container.querySelector("form");

  if (validatePrintUserInfoForm) {
    initFormValidation(validatePrintUserInfoForm, "validatePrintUserInfoForm", handleFormData);
  }

  subscribe("validatePrintUserInfoForm:formSubmitted", () => {
    const messageContainer = container.querySelector(".form__message");
    messageContainer.classList.add("hidden");
  });

  if (invoiceAddressCheckbox) {
    invoiceAddressCheckbox.addEventListener("change", () => {
      invoiceFieldset.classList.toggle("hidden");
      const value = invoiceAddressCheckboxValue.getAttribute("value");
      if (value === "checked") {
        invoiceAddressCheckboxValue.setAttribute("value", "unchecked");
        invoiceFieldset.setAttribute("disabled", true);
        const topRow = document.getElementsByClassName("print-checkout__top-row")[0];
        scrollToElement(topRow);
      } else {
        invoiceAddressCheckboxValue.setAttribute("value", "checked");
        invoiceFieldset.removeAttribute("disabled");
      }
    });
  }

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.errorHtml);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      enableSubmitButton(form);
      publish("klarnaPayment:updatePaywall", data);
      const userInfo = { ...data.userInfo, invoiceAddress: data.address.bn.invoiceAddress, deliveryAddress: data.address.bn.deliveryAddress };
      publish("printCheckout:setupInvoiceOrder", userInfo);
      publish("printCheckout:activatePaymentStep", data.html);
      return;
    }
  }
}

export function CreatePrintOrder(container) {
  const createPrintOrderForm = container.querySelector("form");

  if (createPrintOrderForm) {
    initFormValidation(createPrintOrderForm, "createPrintOrderForm", handleFormData);
  }

  subscribe("createPrintOrderForm:formSubmitted", () => {
    const messageContainer = container.querySelector(".form__message");
    messageContainer.classList.add("hidden");
  });

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.errorHtml);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      const currentUrl = window.location.href.replace(window.location.search, "");
      const next = data.loginLink || `${currentUrl}klart/`;
      publish("printCheckout:orderCreated");
      window.location.replace(next);
      return;
    }
  }
}

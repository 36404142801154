
export {
  isRequired,
  isSsn,
  isEmail,
  isPassword,
  isEqualToField,
  isChecked,
  isName,
  isZipCode,
  isAlphanumeric,
  isNumber,
  isPhoneNumber
};

function isRequired(value) {
  return value.trim().length > 0;
}

function isName(value) {
  if (!isRequired(value)) {
    return false;
  }
  const re = /^[a-zA-ZàáäåèéöüÀÁÄÅÈÉÖÜ ,.'-]+$/u;
  return re.test(value.toLowerCase());
}

function isAlphanumeric(value) {
  if (!isRequired(value)) {
    return false;
  }
  const re = /^[a-zA-ZàáäåèéöüÀÁÄÅÈÉÖÜ0-9 ,.'-]+$/u;
  return re.test(value.toLowerCase());
}

function isSsn(value) {
  const trimmedValue = value.replace(/\D/g, "");
  const tenDigitValue = trimmedValue.substring(trimmedValue.length - 10);
  return _luhnValidate(tenDigitValue);
}

function isZipCode(value) {
  const trimmedValue = value.replace(/\D/g, "");
  return trimmedValue.length === 5;
}

function isEmail(value) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value.toLowerCase());
}

function isPassword(value) {
  return value.length >= 8;
}

function isPhoneNumber(value) {
  if (!isRequired(value)) {
    return false;
  }
  const re = /^$|^[0+][0-9 -]{5,15}$/;
  return (re.test(value));
}

function isNumber(value) {
  const re = /^$|^[0-9]+$/;
  return (re.test(value));
}

function isEqualToField(value, fieldId) {
  const fieldToCompare = document.getElementById(fieldId);
  if (!fieldToCompare) throw new Error(`No field with id ${fieldId}`);

  return value === fieldToCompare.value;
}

function isChecked(el) {
  return el.checked;
}

function _luhnValidate(ssn) {
  const trimmed = ssn.replace(/[\s]/g, "");
  const length = trimmed.length;
  let odd = false;
  let total = 0;
  let calc;
  let calc2;

  if (!/^[0-9]+$/.test(trimmed)) {
    return false;
  }

  for (let i = length; i > 0; i--) {
    calc = parseInt(trimmed.charAt(i - 1));
    if (!odd) {
      total += calc;
    } else {
      calc2 = calc * 2;

      switch (calc2) {
        case 10: calc2 = 1; break;
        case 12: calc2 = 3; break;
        case 14: calc2 = 5; break;
        case 16: calc2 = 7; break;
        case 18: calc2 = 9; break;
        // default: calc2 = calc2;
      }
      total += calc2;
    }
    odd = !odd;
  }

  return (total !== 0 && (total % 10) === 0);
}

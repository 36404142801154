import { publish } from "../../scripts/helpers/pubsub";
import { disableScroll, enableScroll } from "../../scripts/helpers/scroll";

export function Modal(modalEl, { triggers } = {}) {
  if (!modalEl) return;
  const modalId = modalEl.getAttribute("data-modal-id");
  const scrollable = modalEl.getAttribute("data-scrollable") || false;
  const openOnInit = modalEl.getAttribute("data-open") || false;

  if (triggers) {
    triggers.forEach((trigger) => {
      if (trigger.getAttribute("data-open-id") === modalId) {
        trigger.addEventListener("click", open);
      }
    });
  }

  const containerEl = modalEl.getElementsByClassName("js-container")[0] || modalEl.firstElementChild;
  containerEl.addEventListener("click", (e) => e.stopPropagation());

  const closeEl = Array.from(containerEl.getElementsByClassName("js-close"));
  const elements = closeEl.concat(modalEl);

  let focusableEls;
  let focusedElBeforeOpen;
  let firstFocusableEl;
  let lastFocusableEl;

  if (openOnInit) open();

  return {
    open,
    refocus,
    close
  };

  function open(closeable = true) {
    modalEl.setAttribute("aria-hidden", false);
    if (!scrollable) disableScroll();

    refocus(true);

    window.addEventListener("keydown", handleTab);
    if (closeable) {
      elements.forEach((el) => el.addEventListener("click", close));
      window.addEventListener("keydown", keyClose);
    }
  }

  function refocus(rememberLastFocus = false) {
    focusableEls = Array.from(modalEl.querySelectorAll("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex=\"0\"]")).filter(isVisible);
    if (rememberLastFocus) focusedElBeforeOpen = document.activeElement;
    firstFocusableEl = focusableEls[0];
    lastFocusableEl = focusableEls[focusableEls.length - 1];
    if (firstFocusableEl) firstFocusableEl.focus();
  }

  function isVisible( el ) {
    return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
  }

  function close(event) {
    if (event) event.stopPropagation();
    elements.forEach((el) => el.removeEventListener("click", close));
    window.removeEventListener("keydown", handleTab);
    window.removeEventListener("keydown", keyClose);

    if (!scrollable) enableScroll();
    modalEl.setAttribute("aria-hidden", true);
    if (focusedElBeforeOpen) focusedElBeforeOpen.focus();
    publish("modal:closed", { event });
  }

  function keyClose(event) {
    if (event.key === "Escape") close();
  }

  function handleTab(event) {
    const KEY_TAB = 9;

    function handleBackwardTab() {
      if (document.activeElement === firstFocusableEl) {
        event.preventDefault();
        if (lastFocusableEl) lastFocusableEl.focus();
      }
    }
    function handleForwardTab() {
      if (document.activeElement === lastFocusableEl) {
        event.preventDefault();
        if (firstFocusableEl) firstFocusableEl.focus();
      }
    }

    switch (event.keyCode) {
      case KEY_TAB:
        if (focusableEls.length === 1) {
          event.preventDefault();
          break;
        }

        if (event.shiftKey) {
          handleBackwardTab();
        } else {
          handleForwardTab();
        }
        break;
      default:
        break;
    }
  }
}

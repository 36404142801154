export function Tooltip(el) {
  el.addEventListener("click", (e) => {
    e.stopPropagation();
    el.classList.toggle("tooltip--active");
    document.addEventListener("click", handleClickOutside);
  });

  function handleClickOutside(event) {
    if (!event.target.isEqualNode(el)) {
      el.classList.remove("tooltip--active");
      document.removeEventListener("click", handleClickOutside);
    }
  }
}

const handlers = {};

function publish(name, data) {
  const event = new window.CustomEvent(name, {
    detail: data
  });
  window.dispatchEvent(event);
}

function subscribe(name, handler) {
  const internalHandler = ({ detail }) => handler(detail);
  handlers[name] = handlers[name] || [];
  handlers[name].push({ handler, internalHandler });
  window.addEventListener(name, internalHandler);
}

function unsubscribe(name, handler) {
  if (!handlers[name]) return;
  const handlerIndex = handlers[name].findIndex((x) => x.handler === handler);
  if (handlerIndex === -1) return;
  window.removeEventListener(name, handlers[name][handlerIndex].internalHandler);
  handlers[name].splice(handlerIndex, 1);
}

export {
  publish,
  subscribe,
  unsubscribe
};

import { campaignStepTracking, campaignInteraction } from "../../scripts/helpers/tracking";

export function ListPage(listPageEl) {
  if (!listPageEl) return;
  campaignStepTracking(listPageEl);

  const ctaButtons = listPageEl.querySelectorAll(".offer-card__button") || [];
  ctaButtons.forEach((ctaButton) => {
    const label = ctaButton.innerText.trim();
    ctaButton.addEventListener("click", () => {
      campaignInteraction("CTA", label);
    });
  });
}

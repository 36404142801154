import { publish, subscribe } from "@dn/scripts/helpers/pubsub";
import { showErrorMessage, enableSubmitButton, initFormValidation } from "@dn/components/form/form";

export function StudentRenewal(formEl) {

  if (formEl) {
    initFormValidation(formEl, "studentRenewal", handleFormData);
  }

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.message);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      publish("studentRenewal:nextStep", {step: "receipt"});
      publish("progressBar:update", {currentStep: 1});
      return;
    }
  }
}

export function SsnVerification(formEl) {
  if (formEl) {
    initFormValidation(formEl, "ssnVerification", handleFormData);
  }

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.errorHtml);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      const currentUrl = window.location.href.replace(window.location.search, "");
      const next = `${currentUrl}betala/`;
      window.location.replace(next);
      return;
    }
  }
}

export function ChangeOffer(container) {
  const formEl = container.querySelector("form");

  if (formEl) {
    initFormValidation(formEl, "changeOffer", handleFormData);
  }

  function handleFormData(form, data) {
    if (!data.success) {
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      publish("progressBar:update", {currentStep: 1});
      publish("changeOffer:showConfirmation");
      return;
    }
  }
}

export function EmployeeSubscription(container) {
  const formEl = container.querySelector("form");
  if (formEl) {
    initFormValidation(formEl, "employeeSubscription", handleFormData);
  }

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.errorHtml);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      const currentUrl = window.location.href.replace(window.location.search, "");
      const next = `${currentUrl}klart/`;
      window.location.replace(next);
      return;
    }
  }
}

export function LeadsCampaign(container) {

  const leadsForm = container.querySelector("form");
  if (leadsForm) {
    initFormValidation(leadsForm, "leadsForm", handleFormData);
  }

  subscribe("leadsForm:formSubmitted", () => {
    const messageContainer = container.querySelector(".form__message");
    messageContainer.classList.add("hidden");
  });

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.errorHtml);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      const currentUrl = window.location.href.replace(window.location.search, "");
      const next = data.loginLink || `${currentUrl}klart/`;
      window.location.replace(next);
      return;
    }
  }
}


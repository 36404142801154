import { ProgressBar } from "@dn/components/progress-bar/progress-bar";
import { publish, subscribe } from "@dn/scripts/helpers/pubsub";
import { GetPrintOrderDetails, ValidatePrintUserInfo, CreatePrintOrder } from "./print-checkout-form";
import { campaignStepTracking } from "../../scripts/helpers/tracking";
import { getRefs } from "@dn/components/helpers/getRefs";
import { scrollToElement } from "@dn/scripts/helpers/scrollToElement";

export function PrintCheckout(container) {
  const {
    step1,
    step2,
    step2Voucher,
    emailField,
    printReceipt,
    progressBar,
    zipCodeField,
    terms,
    mainForm,
    kpPaywallContainer,
    sectionHeader,
    createInvoiceOrderForm,
    paymentMethodElement,
    orderDataField,
    userInfoSummary,
    changeUserInfoButton,
    klarnaRadioButton,
    invoiceRadioButton
  } = getRefs(container);

  const invoiceOptionRow = document.querySelector(".radio-button-field--invoice");
  const klarnaOptionRow = document.querySelector(".radio-button-field--klarna");

  if (invoiceOptionRow) {
    invoiceOptionRow.addEventListener("click", () => {
      createInvoiceOrderForm.classList.remove("hidden");
      kpPaywallContainer.classList.add("hidden");
      klarnaRadioButton.checked = false;
      invoiceRadioButton.checked = true;
      scrollToElement(paymentMethodElement);
    });
  }

  if (klarnaOptionRow) {

    klarnaOptionRow.addEventListener("click", () => {
      kpPaywallContainer.classList.remove("hidden");
      createInvoiceOrderForm.classList.add("hidden");
      invoiceRadioButton.checked = false;
      klarnaRadioButton.checked = true;
      scrollToElement(paymentMethodElement);
    });
  }

  if (changeUserInfoButton) {
    changeUserInfoButton.addEventListener("click", () => {
      mainForm.classList.remove("hidden");
      paymentMethodElement.classList.add("hidden");
      userInfoSummary.classList.add("hidden");
      changeUserInfoButton.classList.add("hidden");
      invoiceRadioButton.checked = false;
      createInvoiceOrderForm.classList.add("hidden");
      klarnaRadioButton.checked = false;
      kpPaywallContainer.classList.add("hidden");

    });
  }

  function showStep2() {
    if (step1) step1.classList.toggle("hidden");
    if (step2) step2.classList.toggle("hidden");
    if (step2Voucher) step2Voucher.classList.toggle("hidden");
    publish("progressBar:update", {currentStep: 1});
  }

  function updateShortTerms(shortTerms) {
    if (!shortTerms ) return;
    terms.innerHTML = shortTerms;
  }

  function setupStep2Forms(data) {
    const { email, zipCode } = data;
    if (email) emailField.setAttribute("value", email);
    if (zipCode && zipCodeField) zipCodeField.setAttribute("value", zipCode);
  }

  if (progressBar) {
    ProgressBar(progressBar);
  }

  if (step1) {
    campaignStepTracking(container, { stepName: "Email entry", stepNumber: 1});
    GetPrintOrderDetails(step1);
  }

  if (step2) {
    ValidatePrintUserInfo(step2);
  }

  if (step2Voucher) {
    CreatePrintOrder(step2Voucher);
  }

  if (paymentMethodElement) {
    CreatePrintOrder(paymentMethodElement);
  }

  if (printReceipt) {
    campaignStepTracking(printReceipt, { stepName: "Confirmation", stepNumber: 3});
    publish("progressBar:update", {currentStep: 2});
  }

  subscribe("printCheckout:showStep2", (data) => {
    const {email, zipCode, shortTerms } = data;
    const stepName = container.classList.contains("voucher__redeem-section--print") ? "Claim" : "Payment";
    campaignStepTracking(container, { stepName, stepNumber: 2});
    setupStep2Forms({email, zipCode});
    updateShortTerms(shortTerms);
    showStep2();
  });

  subscribe("printCheckout:setupInvoiceOrder", (data) => {
    if (orderDataField) orderDataField.setAttribute("value", JSON.stringify(data));
  });

  subscribe("printCheckout:activatePaymentStep", (data) => {
    mainForm.classList.add("hidden");
    paymentMethodElement.classList.remove("hidden");
    userInfoSummary.innerHTML = data;
    userInfoSummary.classList.remove("hidden");
    changeUserInfoButton.classList.remove("hidden");

    scrollToElement(sectionHeader);
  });

}

import { Header } from "@dn/components/header/header";
import { lazyLoadStylesheets } from "@dn/scripts/plugins/lazyLoadStylesheets";
import { lazyLoadImages } from "@dn/scripts/plugins/lazyLoadImages";
import { PrintCheckout} from "../components/print-checkout/print-checkout";
import { Campaign } from "../components/campaign/campaign";
import { ChangeOfferCampaign } from "../components/campaign/change-offer-campaign";
import { ListPage } from "../components/list-page/list-page";
import { OfferCard } from "@dn/components/li/offer-card/offer-card";
import { Modal } from "@dn/components/modal/modal";
import { Tooltip } from "@dn/components/tooltip/tooltip";
import { VoucherRedeem } from "../components/voucher/voucher";
import { CampaignLandingPage } from "../components/campaign-landing-page/campaign-landing-page";
import { EnterprisePage } from "../components/enterprise-page/enterprise-page";
import { trackMenu } from "./trackMenu";
import { ContentBlock } from "../components/content-block/content-block";
import { Banner } from "../components/banner/banner";

const lazyImages = document.getElementsByClassName("lazy-image");
lazyLoadImages(lazyImages);

lazyLoadStylesheets("lazy-stylesheet");

const headerEl = document.querySelector(".header");
if (headerEl) Header(headerEl);

const printCheckoutEl = document.querySelector(".print-checkout");
if (printCheckoutEl) PrintCheckout(printCheckoutEl);

const campaignEl = document.querySelector(".campaign");
if (campaignEl) Campaign(campaignEl);

const changeOfferEl = document.querySelector(".change-offer-campaign");
if (changeOfferEl) ChangeOfferCampaign(changeOfferEl);

const listPageEl = document.querySelector(".list-page");
if (listPageEl) ListPage(listPageEl);

const campaignLandingPageEl = document.querySelector(".campaign-landing-page");
if (campaignLandingPageEl) CampaignLandingPage(campaignLandingPageEl);

const offerCards = document.querySelectorAll(".offer-card");
if (offerCards.length) OfferCard(offerCards);

const modals = document.querySelectorAll(".modal");
if (modals.length) {
  modals.forEach((element) => {
    const triggers = document.querySelectorAll(".open-modal");
    Modal(element, { triggers });
  });
}

const tooltips = document.querySelectorAll(".tooltip");
if (tooltips.length) {
  tooltips.forEach((tooltip) => {
    Tooltip(tooltip);
  });
}

const contentBlocks = document.querySelectorAll(".content-block__container");
if (contentBlocks.length) {
  contentBlocks.forEach((contentBlock) => {
    ContentBlock(contentBlock);
  });
}

const bannerEl = document.querySelector(".banner");
if (bannerEl) Banner(bannerEl);

const voucherRedeemEl = document.querySelector(".voucher");
if (voucherRedeemEl) VoucherRedeem(voucherRedeemEl);

const enterpriseEl = document.querySelector(".enterprise-page");
if (enterpriseEl) EnterprisePage(enterpriseEl);

trackMenu();

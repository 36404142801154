
export function OfferCard(cards) {
  cards.forEach((card) => {
    const showMoreButton = card.querySelector(".offer-card__more-button");
    if (showMoreButton) {
      showMoreButton.addEventListener("click", () => {
        card.classList.toggle("offer-card--expanded");
      });
    }
  });
}

import { getWindowInnerHeight } from "../helpers/getWindowInnerHeight";
import { addScrollListener, removeScrollListener } from "../helpers/scroll";

export function lazyLoadImages(images = []) {
  if (!images.length) return;

  const loadOffset = getWindowInnerHeight();

  if ("IntersectionObserver" in window) {
    setupIntersectionObserver(images, loadOffset);
  } else {
    setupScrollListener(images, loadOffset);
  }
}

export function lazyLoadImagesInContainer(imageContainer) {
  if (!imageContainer) return;
  const lazyImages = imageContainer.getElementsByClassName("lazy-image");
  for (let i = 0; i < lazyImages.length; i++) {
    loadImage(lazyImages[i]);
  }
}

function loadImage(image) {
  const noscript = image.getElementsByTagName("noscript")[0];
  if (!noscript) return;
  image.insertAdjacentHTML("beforeend", noscript.textContent);
  noscript.parentElement.removeChild(noscript);
}

function setupIntersectionObserver(images, loadOffset) {
  const observer = new window.IntersectionObserver(viewPortUpdate, {
    rootMargin: `${loadOffset}px 0px`,
    threshold: 0.01
  });

  for (let i = 0; i < images.length; ++i) {
    observer.observe(images[i]);
  }

  function viewPortUpdate(entries) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        loadImage(entry.target);
      }
    });
  }
}

function setupScrollListener(elements, loadOffset) {
  const viewportHeight = getWindowInnerHeight();
  const initialScrollPosition = window.pageYOffset;
  const scrolledDistance = {
    startPosition: initialScrollPosition,
    highest: initialScrollPosition,
    lowest: initialScrollPosition
  };
  const pendingImages = {};

  init();

  function init() {
    const images = [];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const { top, bottom } = element.getBoundingClientRect();
      images.push({
        element,
        top,
        bottom
      });
    }

    sortImages(images);
    addScrollListener(handleScroll);
  }

  function reset(scrollPosition) {
    const scrollPositionDelta = scrolledDistance.startPosition - scrollPosition;

    scrolledDistance.startPosition = scrollPosition;
    scrolledDistance.highest = scrollPosition;
    scrolledDistance.lowest = scrollPosition;

    const allPendingImages = pendingImages.above.concat(pendingImages.below);
    const images = [];

    for (let i = 0; i < allPendingImages.length; ++i) {
      const { element, top, bottom } = allPendingImages[i];
      images.push({
        element: element,
        top: top + scrollPositionDelta,
        bottom: bottom + scrollPositionDelta
      });
    }

    sortImages(images);
  }

  function sortImages(images) {
    const above = [];
    const below = [];

    for (let i = 0; i < images.length; ++i) {
      const image = images[i];
      const viewportDelta = signViewportDelta(image.top, image.bottom);

      if (!viewportDelta) {
        loadImage(image.element);
      } else if (viewportDelta > 0) {
        below.push(image);
      } else {
        above.push(image);
      }
    }

    pendingImages.above = above.sort((a, b) => a.top - b.top);
    pendingImages.below = below.sort((a, b) => b.bottom - a.bottom);
  }

  function signViewportDelta(top, bottom) {
    let value = 0;
    if (bottom < -loadOffset)--value;
    if (top > (loadOffset + viewportHeight))++value;
    return Math.sign(value);
  }

  function handleScroll({ scrollY, lastScrollY }) {
    if (alreadyVisited(scrollY)) return;

    const scrollingDown = scrollY > lastScrollY;
    const unexpectedDelta = scrollingDown ? -1 : 1;
    const collection = scrollingDown ? pendingImages.below : pendingImages.above;
    const firstLoopIndex = collection.length - 1;

    for (let i = firstLoopIndex; i >= 0; --i) {
      const { element } = collection[i];
      const { top, bottom } = element.getBoundingClientRect();
      const viewportDelta = signViewportDelta(top, bottom);
      if (i === firstLoopIndex && viewportDelta === unexpectedDelta) return reset(scrollY);
      if (viewportDelta) return;

      loadImage(element);
      collection.pop();
    }

    if (!pendingImages.above.length && !pendingImages.below.length) {
      removeScrollListener(handleScroll);
    }
  }

  function alreadyVisited(currentPosition) {
    const lowest = Math.min(currentPosition, scrolledDistance.lowest);

    if (lowest === currentPosition) {
      scrolledDistance.lowest = lowest;
      return false;
    }

    const highest = Math.max(currentPosition, scrolledDistance.highest);

    if (highest === currentPosition) {
      scrolledDistance.highest = highest;
      return false;
    }

    return true;
  }
}

import { publish, subscribe } from "@dn/scripts/helpers/pubsub";
import { Modal } from "@dn/components/modal/modal";
import {
  initFormValidation,
  showErrorMessage,
  clearErrorMessage,
  enableSubmitButton
} from "@dn/components/form/form";

const cookieMessage = "Du måste aktivera cookies i din webbläsare för att kunna skicka din intresseanmälan.";
const errorMessage = "Kontrollera dina uppgifter och försök igen eller kontakta <a href='https://konto.bonniernews.se/kontakt/dn/'>kundservice.</a>";
let formType = "foretag";

export function EnterprisePage(container) {
  const enterpriseForm = container.querySelector(".enterprise-page__form");

  if (!enterpriseForm) return;

  formType = enterpriseForm.getAttribute("data-form-type") || formType;

  if (areCookiesEnabled()) {
    enableSubmitButton(enterpriseForm);
    ValidateForm(enterpriseForm, container);
  } else {
    showErrorMessage(enterpriseForm, cookieMessage);
  }

  subscribe("enterpriseForm:success", () => {
    const confirmationModalEl = document.getElementById("enterprise-confirmation");
    const modal = Modal(confirmationModalEl);
    modal.open();
  });
}

function ValidateForm(enterpriseform) {
  if (enterpriseform) {
    initFormValidation(enterpriseform, "enterpriseForm", handleFormData);
  }

  subscribe("enterpriseForm:formSubmitted", () => {
    clearErrorMessage(enterpriseform);
  });

  function handleFormData(form, data) {
    if (data.showErrorMessage) {
      showErrorMessage(form, errorMessage);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      publish("enterpriseForm:success", data);
      enableSubmitButton(form);
      return;
    }

    enableSubmitButton(form);
    return;
  }
}

function areCookiesEnabled() {
  let cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    document.cookie = "testcookie=1";
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
  }
  return cookieEnabled;
}

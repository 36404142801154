export function Header(headerEl) {
  const toggleEl = headerEl.querySelector(".header__mobile-nav-toggle");
  if (toggleEl) toggleEl.addEventListener("click", handleClick);

  function handleClick() {
    const targetEl = document.getElementById(toggleEl.getAttribute("aria-controls"));

    if (toggleEl && targetEl) {
      toggleEl.setAttribute("aria-expanded", toggleEl.getAttribute("aria-expanded") !== "true");
      targetEl.setAttribute("aria-hidden", targetEl.getAttribute("aria-hidden") === "false");
    }
  }
}

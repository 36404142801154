import { subscribe, publish } from "@dn/scripts/helpers/pubsub";
import { ProgressBar } from "@dn/components/progress-bar/progress-bar";
import { StudentRenewal, SsnVerification, EmployeeSubscription, LeadsCampaign } from "./campaign-form";
import { campaignStepTracking, campaignInteraction } from "../../scripts/helpers/tracking";
import { checkoutImpression, paywallReceipt, checkoutStart } from "../../scripts/helpers/trackingV4";
import { ProductCard } from "@dn/components/li/product-card/product-card";

export function Campaign(el) {
  const leadsCampaignFormContainer = el.querySelector(".leads-campaign-form");
  if (leadsCampaignFormContainer) {
    LeadsCampaign(el);
    campaignStepTracking(el, { stepName: "Email entry", stepNumber: 1});
  }

  const EmployeeSubscriptionFormContainer = el.querySelector(".employee-subscription-form");
  if (EmployeeSubscriptionFormContainer) {
    EmployeeSubscription(el);
    campaignStepTracking(el, { stepName: "Email entry", stepNumber: 1});
  }

  const studentRenewalFormContainer = el.querySelector(".student-renewal-verification-form");
  if (studentRenewalFormContainer) {
    StudentRenewal(studentRenewalFormContainer);
    if (el.getAttribute("data-tracking-params")) {
      campaignStepTracking(el);
    }
  }

  const isChangeOffer = document.querySelector(".change-offer-campaign");

  subscribe("kpPaywall:loaded", () => {
    if (!isChangeOffer) {
      campaignStepTracking(el, { stepName: "Email entry", stepNumber: 1});
      checkoutImpression(el);
    }
  });

  const ssnCampaignCheckout = el.querySelector(".ssn-campaign-checkout");
  subscribe("kisPaywall:loaded", () => {
    const stepNumber = ssnCampaignCheckout ? 2 : 1;
    campaignStepTracking(el, { stepName: "Email entry", stepNumber});
    checkoutImpression(el);
  });

  subscribe("kisPaywall:firstBuyButtonClicked", () => {
    const stepNumber = ssnCampaignCheckout ? 3 : 2;
    campaignStepTracking(el, { stepName: "Payment", stepNumber});
    checkoutStart(el);
  });

  subscribe("kpPaywall:firstBuyButtonClicked", () => {
    if (!isChangeOffer) {
      campaignStepTracking(el, { stepName: "Payment", stepNumber: 2});
      checkoutStart(el);
    }
  });

  const ssnVerificationForm = el.querySelector(".ssn-verification-form");
  if (ssnVerificationForm) {
    SsnVerification(ssnVerificationForm);
    if (el.getAttribute("data-tracking-params")) {
      campaignStepTracking(el, { stepName: "Eligibility check", stepNumber: 1});
    }
  }

  const productCard = el.querySelector(".product-card");
  if (productCard) ProductCard(productCard);

  const kisPaywallEl = el.querySelector(".kis-paywall");
  const upgradeCheckbox = el.querySelector(".js-upgrade input");
  if (upgradeCheckbox) {
    upgradeCheckbox.addEventListener("change", () => {
      if (kisPaywallEl) {
        publish("kisPaywall:change", { active: upgradeCheckbox.checked });
      }
    });
  }

  subscribe("campaign:nextStep", (data) => {
    el.classList.remove(`campaign--${data.prevStep}`);
    el.classList.add(`campaign--${data.step}`);
  });

  subscribe("leadsCampaign:nextStep", (data) => {
    el.classList.remove(`campaign--${data.prevStep}`);
    el.classList.add(`campaign--${data.step}`);

    if (data.step === "receipt") {
      const receiptContainer = el.querySelector(".campaign__receipt");
      campaignStepTracking(receiptContainer);
    }
  });

  subscribe("studentRenewal:nextStep", (data) => {
    el.classList.remove(`campaign--${data.prevStep}`);
    el.classList.add(`campaign--${data.step}`);

    if (data.step === "receipt") {
      const receiptContainer = el.querySelector(".campaign__receipt");
      campaignStepTracking(receiptContainer);
    }
  });

  subscribe("ssnCampaign:nextStep", (data) => {
    el.classList.remove(`campaign--${data.prevStep}`);
    el.classList.add(`campaign--${data.step}`);

    if (data.step === "receipt") {
      const receiptContainer = el.querySelector(".campaign__receipt");
      campaignStepTracking(receiptContainer);
    }
  });

  subscribe("campaign:formMessage", (data) => {
    const messageContainer = el.querySelector(".form__message");
    if (data.hide) {
      messageContainer.classList.add("hidden");
    } else {
      messageContainer.classList.remove("hidden");
      messageContainer.classList.add(`form__message--${data.type}`);
      messageContainer.innerHTML = data.message;
    }
  });

  const progressBar = el.querySelector(".progress-bar");
  const intialAnimation = !ssnCampaignCheckout ? true : false;
  if (progressBar) ProgressBar(progressBar, intialAnimation);

  const receiptPage = document.querySelector(".js-campaign-receipt");
  const leadsReceiptPage = document.querySelector(".js-leads-receipt");
  const employeeReceiptPage = document.querySelector(".js-employee-receipt");
  const identityVerificationPage = document.querySelector(".js-ssn-receipt");
  const isKlarna = document.querySelector(".js-klarna-receipt");
  const isMultiStep = document.querySelector(".js-multi-step");

  let currentStep;
  if (leadsReceiptPage || employeeReceiptPage || (receiptPage && isKlarna && !isMultiStep)) {
    currentStep = 1;
  } else {
    currentStep = 2;
  }

  if (receiptPage || leadsReceiptPage || employeeReceiptPage) {
    publish("campaign:nextStep", {step: "receipt"});
    publish("progressBar:update", {currentStep});
  }

  let confirmationStep;
  if (isKlarna && !isMultiStep) {
    confirmationStep = currentStep + 2;
  } else if (identityVerificationPage) {
    confirmationStep = 4;
  } else {
    confirmationStep = currentStep + 1;
  }

  const receiptContainer = document.querySelector(".campaign__receipt");
  if (receiptContainer) {
    campaignStepTracking(receiptContainer, {stepName: "Confirmation", stepNumber: confirmationStep});
    if (!studentRenewalFormContainer) {
      paywallReceipt(receiptContainer);
    }
    const button = receiptContainer.querySelector(".button");
    if (button) {
      button.addEventListener("click", () => campaignInteraction("CTA", button.innerText.trim()));
    }
  }
}

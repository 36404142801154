const supported = supportsPassive();

export function passiveIfPossible(passive = true) {
  return supported ? {passive} : false;
}

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
function supportsPassive() {
  // Test via a getter in the options object to see if the passive property is accessed
  let supportPassiveCalled = false;

  try {
    const opts = Object.defineProperty({}, "passive", {
      get: function () {
        supportPassiveCalled = true;
        return true;
      }
    });
    window.addEventListener("testpassive", null, opts);
  } catch (e) {
    return false;
  }

  return supportPassiveCalled;
}

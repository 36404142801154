import { campaignStepTracking, campaignInteraction } from "../../scripts/helpers/tracking";

export function CampaignLandingPage(el) {
  if (!el) return;

  if (!el.querySelector(".print-checkout")) campaignStepTracking(el);

  const ctaButtons = el.querySelectorAll(".offer-card__button") || [];
  ctaButtons.forEach((ctaButton) => {
    const label = ctaButton.innerText.trim();
    ctaButton.addEventListener("click", () => {
      campaignInteraction("CTA", label);
    });
  });
}

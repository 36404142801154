import { campaignInteraction } from "../../scripts/helpers/tracking";

export function Banner(container) {
  if (!container) return;
  const bannerLink = container.querySelector("a");

  if (!bannerLink) return;

  const label = container.innerText.trim();
  bannerLink.addEventListener("click", () => {
    campaignInteraction("CTA", label);
  });
}

import { publish } from "@dn/scripts/helpers/pubsub";
import { isEmail, isSsn, isRequired, isName, isPassword, isEqualToField, isChecked, isPhoneNumber, isZipCode, isAlphanumeric, isNumber } from "./validators";

export {
  initFormValidation,
  showErrorMessage,
  enableSubmitButton,
  formToJson,
  submitForm,
  validateFormFields,
  validateForm,
  clearErrorMessage,
  passwordToggle
};

function initFormValidation(form, formName, handleFormData) {
  form.setAttribute("novalidate", "");
  const submitButton = form.querySelector("[type=submit]");
  const inputs = Array.from(form.querySelectorAll("input"));
  const selects = Array.from(form.querySelectorAll("select"));
  const fields = inputs.concat(selects);

  if (form.getAttribute("data-validate")) {
    form.setAttribute("data-valid", false);
    validateFormFields(fields, false);
  }

  submitButton.addEventListener("click", () => {
    validateFormFields(fields);
  });

  form.addEventListener("submit", (event) => {
    event.preventDefault();
    // Remove error message if visible
    publish(`${formName}:formSubmitted`);
    // Show error messages on submit
    if (validateForm(fields)) {
      publish(`${formName}:formValidatedAndSubmitted`);
      submitForm(form, handleFormData);
    }
  });
}

function submitForm(form, handleFormData) {
  const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const formData = formToJson(form);
  disableSubmitButton(form);

  window.fetch(form.action, {
    credentials: "same-origin",
    headers: {
      "CSRF-Token": token,
      "Content-Type": "application/json"
    },
    body: formData,
    method: form.method
  })
    .then((response) => {
      if (response.status !== 200 && response.status !== 400 && response.status !== 429) {
        publish("form:errorOnSubmit");
        showErrorMessage(form, "Det uppstod ett oväntat fel. Försök igen senare eller kontakta kundservice.");
      }

      if (response.status === 429) {
        showErrorMessage(form, "För många anrop på för kort tid. Försök igen om en stund.");
      }

      return response.json();
    })
    .then((data) => {
      const invalidInputs = Array.from(form.querySelectorAll("[data-valid='false']"));
      invalidInputs.forEach((el) => {
        el.setAttribute("data-valid", true);
        el.parentElement.classList.remove("invalid");
      });

      if (data.errors) {
        data.errors.forEach((error) => {
          const input = document.getElementById(error.param);
          const field = input.parentElement;
          const errEl = field.getElementsByClassName("js-field-error")[0];

          input.setAttribute("data-valid", false);
          field.classList.add("invalid");
          errEl.textContent = error.msg;
        });
      }

      handleFormData(form, data);
    })
    .catch((e) => {
      enableSubmitButton(form);
      // eslint-disable-next-line no-console
      console.log(e);
    });
}

function showErrorMessage(form, message) {
  const messageContainer = form.querySelector(".form__message");
  messageContainer.classList.remove("hidden");
  messageContainer.classList.add("form__message--error");
  messageContainer.innerHTML = message;
}

function clearErrorMessage(form) {
  const messageContainer = form.querySelector(".form__message");
  messageContainer.classList.add("hidden");
  messageContainer.classList.remove("form__message--error");
  messageContainer.innerHTML = "";
}

function disableSubmitButton(form) {
  const submitButton = form.querySelector(".button");
  if (submitButton) {
    submitButton.classList.add("button--disabled");
    submitButton.classList.add("button--loading");
    submitButton.disabled = true;
  }
  const dsSubmitButton = form.querySelector(".ds-btn");
  if (dsSubmitButton) {
    dsSubmitButton.classList.add("ds-loading");
    dsSubmitButton.disabled = true;
  }
}

function enableSubmitButton(form) {
  const submitButton = form.querySelector(".button");
  if (submitButton) {
    submitButton.classList.remove("button--disabled");
    submitButton.classList.remove("button--loading");
    submitButton.disabled = false;
  }
  const dsSubmitButton = form.querySelector(".ds-btn");
  if (dsSubmitButton) {
    dsSubmitButton.classList.remove("ds-loading");
    dsSubmitButton.disabled = false;
  }
}

function validateFormFields(inputs, validateOnRender = true) {
  inputs.forEach((input) => {
    // validate each field
    validate(input);
    // remove event listener if it exist
    input.removeEventListener("blur", validate);
    // attach validation on field blur
    input.addEventListener("blur", validate);
  });

  function validate(input) {
    const el = input.target ? input.target : input;

    const validationType = el.getAttribute("data-validation");
    validationType ? el.setAttribute("data-valid", false) : el.setAttribute("data-valid", true);
    const field = el.parentElement;
    const errEl = field.getElementsByClassName("js-field-error")[0];
    const defaultError = errEl.getAttribute("data-default-error");

    const label = getLabel(el);

    if (el.classList.contains("text-field") && el.hasAttribute("required") && !isRequired(el.value)) {
      if (label) {
        errEl.textContent = `Fyll i ${label}`;
      } else {
        errEl.textContent = "Fältet är tomt";
      }
    } else if (defaultError) {
      errEl.textContent = defaultError;
    }

    if (validateOnRender || isATextFieldWithValue(el)) {
      const isValid = validateInputElement(el, validationType);
      if (!isValid) publish("form:invalidInput", {el, validationType});
    }
  }
}

function getLabel(input) {
  const inputName = input.getAttribute("name");
  const labelEl = document.querySelector(`[for="${inputName}"]`);
  if (labelEl) {
    return labelEl.textContent.toLowerCase();
  }
}

function isATextFieldWithValue(el) {
  return el.classList.contains("text-field") && el.value;
}

function validateInputElement(el, validationType) {
  let isValid;

  switch (validationType) {
    case "ssn": isValid = isSsn(el.value); break;
    case "email": isValid = isEmail(el.value); break;
    case "required": isValid = isRequired(el.value); break;
    case "name": isValid = isName(el.value); break;
    case "password": isValid = isPassword(el.value); break;
    case "equals-password": isValid = isEqualToField(el.value, "password"); break;
    case "equals-new-password": isValid = isEqualToField(el.value, "new-password"); break;
    case "checked": isValid = isChecked(el); break;
    case "phonenumber": isValid = isPhoneNumber(el.value); break;
    case "zipcode": isValid = isZipCode(el.value); break;
    case "alpha-numeric": isValid = isAlphanumeric(el.value); break;
    case "number": isValid = isNumber(el.value); break;
    default: isValid = true;
  }

  if (isValid) {
    el.setAttribute("data-valid", true);
    el.parentElement.classList.remove("invalid");
  } else {
    el.setAttribute("data-valid", false);
    el.parentElement.classList.add("invalid");
  }

  return isValid;
}

function validateForm(inputs) {
  const invalidInputs = Array.from(inputs).filter((x) => x.getAttribute("data-valid") === "false");
  return invalidInputs.length < 1;
}

function formToJson(form) {
  const formData = {};
  const elements = Array.from(form.querySelectorAll("input, select, textarea"));

  elements.forEach((el) => {
    if (el.name) {
      if (el.type === "checkbox") {
        formData[el.name] = el.checked ? "on" : null;
      } else {
        formData[el.name] = el.value;
      }
    }
  });

  return JSON.stringify(formData);
}

function passwordToggle(form) {
  const toggleFields = Array.from(form.querySelectorAll("input[type='password'].password-toggle"));

  toggleFields.forEach((el) => {
    const toggleIcon = el.nextElementSibling;
    if (!toggleIcon || !toggleIcon.classList.contains("password-toggle__icon")) return;

    toggleIcon.addEventListener("click", () => {
      el.setAttribute("type", el.getAttribute("type") === "password" ? "text" : "password");
    });
  });
}

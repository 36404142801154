import { dataLayerPush } from "@dn/scripts/helpers/dataLayerPush";

const generateUniqueId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random().toString(36).substr(2, 9)}`;
};

const receiptPageTracking = (containerEl) => {
  const trackingParams = JSON.parse(containerEl.getAttribute("data-tracking-params"));

  dataLayerPush({
    event: "Flow Impression",
    flowInfo: {
      flowId: trackingParams.activityCode || undefined,
      flowName: trackingParams.flowName || undefined,
      stepName: "Purchase Receipt",
      stepVariant: undefined,
      flowVariant: undefined,
      period: undefined,
      product: trackingParams.productName || undefined
    },
    ecommerce: {
      currencyCode: "SEK",
      purchase: {
        actionField: {
          id: generateUniqueId(),
          affiliation: "DN",
        },
        products: [{
          name: trackingParams.productName || undefined,
          id: trackingParams.productId || undefined,
          brand: "DN",
          category: "Subscriptions",
          quantity: 1,
          period: undefined,
          purchaseType: "Purchase",
          paymentType: undefined
        }]
      }
    }
  });
};

const campaignStepTracking = (containerEl, additionalParams = {}) => {
  let trackingParams = JSON.parse(containerEl.getAttribute("data-tracking-params"));
  trackingParams = {...trackingParams, ...additionalParams};

  let trackingData = {
    event: "Campaign Impression",
    campaignInfo: {
      position: trackingParams.position,
      name: trackingParams.name,
      stepName: trackingParams.stepName,
      stepNumber: trackingParams.stepNumber,
      product: trackingParams.product,
      segment: trackingParams.segment || "N/A",
      activityCode: trackingParams.activityCode,
      campaignId: trackingParams.campaignId,
      type: trackingParams.type
    }
  };

  if (trackingParams.stepName === "Confirmation") {
    const ecommerceData = {
      ecommerce: {
        currencyCode: "SEK",
        purchase: {
          actionField: {
            id: generateUniqueId(),
            affiliation: "DN",
          },

          products: [{
            name: trackingParams.product,
            id: trackingParams.productId,
            brand: "DN",
            category: "Subscriptions",
            quantity: 1,
            purchaseType: trackingParams.type
          }]
        }
      }
    };

    trackingData = {...trackingData, ...ecommerceData};
  }

  dataLayerPush(trackingData);
};

const paywallStepTracking = (containerEl, additionalParams = {}) => {
  let trackingParams = JSON.parse(containerEl.getAttribute("data-tracking-params"));
  trackingParams = {...trackingParams, ...additionalParams};

  let trackingData = {
    event: "Paywall Impression",
    campaignInfo: {
      position: trackingParams.position,
      name: trackingParams.name,
      stepName: trackingParams.stepName,
      stepNumber: trackingParams.stepNumber,
      product: trackingParams.product,
      segment: trackingParams.segment || "N/A",
      activityCode: trackingParams.activityCode,
      campaignId: trackingParams.campaignId,
      type: trackingParams.type,
      paywallType: trackingParams.paywallType,
      flowType: trackingParams.flowType
    }
  };

  if (trackingParams.stepName === "Confirmation" || trackingParams.stepName === "One Click Upgrade") {
    const ecommerceData = {
      ecommerce: {
        currencyCode: "SEK",
        purchase: {
          actionField: {
            id: generateUniqueId(),
            affiliation: "DN",
          },

          products: [{
            name: trackingParams.product,
            id: trackingParams.productId,
            brand: "DN",
            category: "Subscriptions",
            quantity: 1,
            purchaseType: trackingParams.type
          }]
        }
      }
    };

    trackingData = {...trackingData, ...ecommerceData};
  }

  dataLayerPush(trackingData);
};

const appPaywallPageViewTracking = (containerEl) => {
  const gtmData = JSON.parse(containerEl.dataset.gtm);
  dataLayerPush(gtmData);
};

// Site campaign - popup, bottom bar, buddy
const siteCampaignTracking = (containerEl) => {
  const trackingParams = JSON.parse(containerEl.getAttribute("data-tracking-params"));
  dataLayerPush({
    event: "Campaign Impression",
    campaignInfo: {
      position: trackingParams.position,
      name: trackingParams.name,
      stepName: "Promotion",
      stepNumber: 0,
      product: trackingParams.product || "N/A",
      segment: trackingParams.segment,
      activityCode: trackingParams.activityCode,
      campaignId: trackingParams.campaignId,
      type: trackingParams.type
    }
  });
};

const campaignInteraction = (context, label) => {
  dataLayerPush({
    event: "Campaign Interaction",
    eventInfo: {
      context,
      label
    }
  });
};

const menuClickTracking = (linkType, linkLabel) => {
  dataLayerPush({
    event: "Menu Click",
    eventInfo: {
      linkType,
      linkLabel
    }
  });
};

export {
  receiptPageTracking,
  campaignStepTracking,
  siteCampaignTracking,
  campaignInteraction,
  paywallStepTracking,
  appPaywallPageViewTracking,
  generateUniqueId,
  menuClickTracking
};

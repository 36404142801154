import { campaignInteraction } from "../../scripts/helpers/tracking";

export function ContentBlock(container) {
  if (!container) return;
  const ctaButtons = container.querySelectorAll(".content-block__button") || [];
  ctaButtons.forEach((ctaButton) => {
    const label = ctaButton.innerText.trim();
    ctaButton.addEventListener("click", () => {
      campaignInteraction("CTA", label);
    });
  });
}

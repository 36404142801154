import { getRefs } from "@dn/components/helpers/getRefs";
import { scrollToElement } from "@dn/scripts/helpers/scrollToElement";
import { subscribe } from "@dn/scripts/helpers/pubsub";
import { ChangeOffer } from "./campaign-form";
import { campaignStepTracking } from "../../scripts/helpers/tracking";

export function ChangeOfferCampaign(el) {
  const {
    currentPaymentMethodButton, klarnaOptionButton, declineBtn,
    klarnaPaywall, changeOfferForm, backButton, productCard, upgradeReceipt,
    paywallContainer, uspList, loginInfoMessage, contentBlocks, topSection,
    deliveryInfoMessage, deliveryAddressBox, changeDeliveryAddressButton, deliveryAddressFormContainer,
    newDeliveryAddressValue, form
  } = getRefs(el);

  if (changeOfferForm) {
    ChangeOffer(changeOfferForm);
  }

  if (el) {
    campaignStepTracking(el, { stepName: "Eligibility check", stepNumber: 1});
  }

  subscribe("changeOffer:showConfirmation", () => {
    campaignStepTracking(el, { stepName: "Confirmation", stepNumber: 2});
    if (uspList) uspList.classList.add("hidden");
    paywallContainer.classList.add("campaign__column--center");
    upgradeReceipt.classList.remove("hidden");
    if (changeOfferForm) changeOfferForm.classList.add("hidden");
    productCard.classList.add("hidden");
    loginInfoMessage.classList.add("hidden");
    loginInfoMessage.classList.add("hidden");
    if (klarnaPaywall) klarnaPaywall.classList.add("hidden");
    contentBlocks.classList.add("hidden");
    topSection.classList.add("hidden");
    if (deliveryInfoMessage) deliveryInfoMessage.classList.add("hidden");
    if (deliveryAddressBox) deliveryAddressBox.classList.add("hidden");
  });

  if (currentPaymentMethodButton) {
    currentPaymentMethodButton.addEventListener("click", () => {
      if (klarnaOptionButton) klarnaOptionButton.classList.add("hidden");
      changeOfferForm.classList.remove("hidden");
      if (!deliveryAddressBox && deliveryAddressFormContainer) deliveryAddressFormContainer.classList.remove("hidden");
      if (deliveryAddressBox) deliveryAddressBox.classList.remove("hidden");
      currentPaymentMethodButton.classList.add("hidden");
    });
  }

  if (klarnaOptionButton) {
    klarnaOptionButton.addEventListener("click", () => {
      klarnaPaywall.classList.remove("hidden");
      klarnaOptionButton.classList.add("hidden");
      if (currentPaymentMethodButton) currentPaymentMethodButton.classList.add("hidden");
      scrollToElement(klarnaPaywall);
    });
  }

  if (backButton) {
    backButton.addEventListener("click", () => {
      if (klarnaPaywall) klarnaPaywall.classList.add("hidden");
      if (klarnaOptionButton) klarnaOptionButton.classList.remove("hidden");
      if (currentPaymentMethodButton) currentPaymentMethodButton.classList.remove("hidden");
      scrollToElement(klarnaPaywall);
    });
  }

  if (declineBtn) {
    declineBtn.addEventListener("click", () => {
      if (changeOfferForm) {
        changeOfferForm.classList.add("hidden");
        if (form) form.reset();
      }
      if (klarnaOptionButton) klarnaOptionButton.classList.remove("hidden");
      if (currentPaymentMethodButton) currentPaymentMethodButton.classList.remove("hidden");
      if (deliveryAddressFormContainer) deliveryAddressFormContainer.classList.add("hidden");
      if (deliveryAddressBox) deliveryAddressBox.classList.remove("hidden");
      if (newDeliveryAddressValue) newDeliveryAddressValue.setAttribute("value", "unchecked");
    });
  }

  if (changeDeliveryAddressButton) {
    changeDeliveryAddressButton.addEventListener("click", () => {
      if (deliveryAddressFormContainer) deliveryAddressFormContainer.classList.remove("hidden");
      if (deliveryAddressBox) deliveryAddressBox.classList.add("hidden");
      if (newDeliveryAddressValue) newDeliveryAddressValue.setAttribute("value", "checked");
    });
  }
}


import { subscribe } from "../../scripts/helpers/pubsub";

export function ProgressBar(el, initialAnimation = true) {
  if (!el) return;

  const stepCount = el.querySelectorAll(".progress-bar__item").length;

  // Initial animation with delay
  if (initialAnimation) update(1);

  subscribe("progressBar:update", (data) => {
    const currentStep = data.currentStep || 1;
    const nextStep = currentStep + 1;
    update(nextStep);
  });

  function update(nextStep) {
    const classSuffix = nextStep === stepCount ? "end" : nextStep === 1 ? "start" : "middle";
    // Update animation on next frame
    requestAnimationFrame(() => {
      el.classList.remove("progress-bar--start", "progress-bar--middle", "progress-bar--end");
      el.classList.add(`progress-bar--${classSuffix}`);
    });
  }
}

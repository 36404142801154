import { menuClickTracking } from "./helpers/tracking";

export function trackMenu() {

  const headerMenuEl = document.getElementsByClassName("header__top-navigation")[0];
  if (headerMenuEl) {
    const headerMenuLinks = Array.from(headerMenuEl.getElementsByClassName("header__link"));
    headerMenuLinks.forEach((link) => {
      link.addEventListener("click", () => {
        menuClickTracking("Header Main Menu", link.innerText);
      });
    });
  }

  const mainMenuEl = document.getElementsByClassName("header__main-navigation")[0];
  if (mainMenuEl) {
    const mainMenuLinks = Array.from(mainMenuEl.getElementsByClassName("header__link"));
    mainMenuLinks.forEach((link) => {
      link.addEventListener("click", () => {
        menuClickTracking("Main Menu", link.innerText);
      });
    });
  }

  const mobileMenuWrappers = Array.from(document.getElementsByClassName("header__mobile-navigation"));
  mobileMenuWrappers.forEach((wrapper) => {
    const mobileMenuLinks = Array.from(wrapper.getElementsByClassName("header__link"));
    mobileMenuLinks.forEach((link) => {
      link.addEventListener("click", () => {
        menuClickTracking("Mobile Main Menu", link.innerText);
      });
    });
  });

  const footerMenuLinks = Array.from(document.getElementsByClassName("footer__link"));
  footerMenuLinks.forEach((link) => {
    link.addEventListener("click", () => {
      menuClickTracking("Footer Menu", link.innerText);
    });
  });
}


export function getRefs(element) {
  const elRefs = element.querySelectorAll("[data-ref]");

  const refs = {};

  for (let index = 0; index < elRefs.length; index++) {
    const el = elRefs[index];
    const refName = el.getAttribute("data-ref");

    if (refs[refName] && Array.isArray(refs[refName])) {
      refs[refName].push(el);
    } else if (refs[refName]) {
      refs[refName] = [refs[refName], el];
    } else {
      refs[refName] = el;
    }
  }

  return refs;
}

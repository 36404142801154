export function throttle(fn, threshold, scope) {
  threshold = threshold || 250;
  let last, deferTimer;

  return function throttled() {
    const context = scope || this;
    const now = +(new Date()), args = arguments;

    if (last && now < last + threshold) {
      window.clearTimeout(deferTimer);
      deferTimer = window.setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

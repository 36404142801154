import { throttle } from "./throttle";
import { passiveIfPossible } from "./passiveIfPossible";
import { getWindowInnerHeight } from "./getWindowInnerHeight";
import { isScrollBehaviorSupported } from "./isScrollBehaviorSupported";

let listeningToScroll = false;
const scrollListeners = [];
let lastScrollX = window.pageXOffset;
let lastScrollY = window.pageYOffset;
let scrollPosition;

export function removeScrollListener(fn) {
  const index = scrollListeners.indexOf(fn);
  if (index === -1) return;

  scrollListeners.splice(index, 1);
}

export function addScrollListener(fn, immediate) {

  if (scrollListenerExists(fn)) return;

  scrollListeners.push(fn);

  if (immediate) {
    fn(lastScrollY, lastScrollY);
  }

  if (listeningToScroll) return;
  listeningToScroll = true;

  window.addEventListener("scroll", throttle(scroll, 70), passiveIfPossible());
}

export function isContainerInView(container) {
  if (container) {
    const containerMiddle = container.getBoundingClientRect().top + container.offsetHeight / 2;
    if (containerMiddle > 0 && containerMiddle < getWindowInnerHeight()) {
      return true;
    }
  }

  return false;
}

export function getScrollX() {
  return lastScrollX;
}

export function getScrollY() {
  return lastScrollY;
}

export function smoothY(y) {
  if (isScrollBehaviorSupported()) {
    window.scrollBy(null, y);
  } else {
    tween(window.pageYOffset, y, (value) => {
      window.scroll(null, value);
    });
  }
}

export function tween(initial, target, fn, callback) {
  const SCROLL_DURATION = 30;
  const stepX = Math.PI / SCROLL_DURATION;
  let stepCount = 0;
  window.requestAnimationFrame(step);

  function step() {
    if (stepCount < SCROLL_DURATION) {
      stepCount++;
      const value = initial + (target * 0.25 * Math.pow((1 - Math.cos(stepX * stepCount)), 2));
      fn(Math.round(value));
      window.requestAnimationFrame(step);
    } else if (callback) {
      return callback();
    }
  }
}

function scroll() {
  const scrollX = window.pageXOffset;
  const scrollY = window.pageYOffset;
  const currentListeners = scrollListeners.slice();
  for (let i = 0; i < currentListeners.length; ++i) {
    currentListeners[i]({
      scrollX,
      lastScrollX,
      scrollY,
      lastScrollY
    });
  }

  lastScrollX = scrollX;
  lastScrollY = scrollY;
}

function scrollListenerExists(fn) {
  return scrollListeners.indexOf(fn) > -1;
}

export function disableScroll() {
  scrollPosition = window.pageYOffset;

  if (isScrollBehaviorSupported()) {
    document.documentElement.style.scrollBehavior = "unset";
  }

  const isSiteModal = document.getElementById("dnse-popup") || document.getElementById("dnse-site-popup");

  if (!isSiteModal) {
    document.body.style.position = "fixed";
    document.body.style.left = "0";
    document.body.style.right = "0";
    document.body.style.overflowY = "scroll";
    document.body.style.top = `${-scrollPosition}px`;
    return;
  }

  const adsBlocked = document.getElementsByTagName("html")[0].classList.contains("ads-blocked");

  if (!adsBlocked) {
    document.body.classList.add("no-scroll");
  }
}

export function enableScroll() {
  const isSitePopup = document.getElementById("dnse-popup") || document.getElementById("dnse-site-popup");

  if (isSitePopup) {
    document.body.classList.remove("no-scroll");
  } else {
    document.body.style.position = "";
    document.body.style.left = "";
    document.body.style.right = "";
    document.body.style.overflowY = "";
    document.body.style.top = "";
    window.scroll(0, scrollPosition);
  }

  if (isScrollBehaviorSupported()) {
    document.documentElement.style.scrollBehavior = "";
  }
}

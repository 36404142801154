import { publish, subscribe } from "@dn/scripts/helpers/pubsub";
import { campaignStepTracking } from "../../scripts/helpers/tracking";
import { initFormValidation, showErrorMessage, enableSubmitButton } from "@dn/components/form/form";
import { PrintCheckout } from "../print-checkout/print-checkout";
import { getRefs } from "@dn/components/helpers/getRefs";

export function VoucherRedeem(container) {
  const {
    validateVoucher,
    claimDigitalVoucher,
    claimPrintVoucher
  } = getRefs(container);

  if (validateVoucher) ValidateVoucher(validateVoucher);

  if (claimDigitalVoucher) ClaimDigitalVoucher(claimDigitalVoucher);

  subscribe("validateVoucher:showDigital", (data) => {

    claimDigitalVoucher.setAttribute("data-tracking-params", JSON.stringify(data.trackingParams));

    const title = claimDigitalVoucher.querySelector(".voucher-card__title");
    if (title) title.innerHTML = data.product.title;
    const description = claimDigitalVoucher.querySelector(".voucher-card__description");
    if (description) description.innerHTML = data.voucherDescription;
    const shortTerms = claimDigitalVoucher.querySelector(".voucher-card__short-terms");
    if (shortTerms) shortTerms.innerHTML = data.shortTerms;
    // Temp fix as x-web-channel is not set by Akamai
    const featureEls = claimDigitalVoucher.querySelectorAll(".usp-list");
    if (featureEls.length > 0) {
      for (let index = 0; index < featureEls.length; index++) {
        const el = featureEls[index];
        el.innerHTML = data.featureHtml;
      }
    }
    const terms = claimDigitalVoucher.querySelector(".voucher__redeem-terms");
    if (terms) terms.innerHTML = data.redeemTerms;
    const voucherCode = claimDigitalVoucher.querySelector("#digitalVoucherCode");
    if (voucherCode) voucherCode.setAttribute("value", data.voucherCode);

    campaignStepTracking(claimDigitalVoucher, { stepName: "Email entry", stepNumber: 1});

    validateVoucher.classList.add("hidden");
    claimDigitalVoucher.classList.remove("hidden");
  });

  subscribe("validateVoucher:showPrint", (data) => {
    claimPrintVoucher.setAttribute("data-tracking-params", JSON.stringify(data.trackingParams));
    PrintCheckout(claimPrintVoucher);

    const title = claimPrintVoucher.querySelector(".voucher-card__title");
    if (title) title.innerHTML = data.product.title;
    const description = claimPrintVoucher.querySelector(".voucher-card__description");
    if (description) description.innerHTML = data.voucherDescription;
    const shortTerms = claimPrintVoucher.querySelector(".voucher-card__short-terms");
    if (shortTerms) shortTerms.innerHTML = data.shortTerms;
    // Temp fix as x-web-channel is not set by Akamai
    const featureEls = claimPrintVoucher.querySelectorAll(".usp-list");
    if (featureEls.length > 0) {
      for (let index = 0; index < featureEls.length; index++) {
        const el = featureEls[index];
        el.innerHTML = data.featureHtml;
      }
    }
    const terms = claimPrintVoucher.querySelector(".voucher__redeem-terms");
    if (terms) terms.innerHTML = data.redeemTerms;
    // Temp fix as x-web-channel is not set by Akamai
    const deliveryEls = claimPrintVoucher.querySelectorAll(".voucher__redeem-delivery-info");
    if (deliveryEls.length > 0) {
      for (let index = 0; index < deliveryEls.length; index++) {
        const el = deliveryEls[index];
        el.innerHTML = data.deliveryHtml;
      }
    }
    const identificationVoucherCode = claimPrintVoucher.querySelector("#identificationVoucherCode");
    if (identificationVoucherCode) identificationVoucherCode.setAttribute("value", data.voucherCode);
    const mainVoucherCode = claimPrintVoucher.querySelector("#mainVoucherCode");
    if (mainVoucherCode) mainVoucherCode.setAttribute("value", data.voucherCode);
    const changeVoucherCode = claimPrintVoucher.querySelector("#changeVoucherCode");
    if (changeVoucherCode) changeVoucherCode.setAttribute("value", data.voucherCode);

    validateVoucher.classList.add("hidden");
    claimPrintVoucher.classList.remove("hidden");

  });

  subscribe("printCheckout:orderCreated", () => {
    campaignStepTracking(claimPrintVoucher, { stepName: "Confirmation", stepNumber: 3});
  });

  subscribe("printCheckout:updateFormValues", () => {
    togglePrintPackageInfo();
  });

  function togglePrintPackageInfo() {
    const delivery = claimPrintVoucher.querySelector(".voucher__redeem-delivery-info--mobile");
    if (delivery) delivery.classList.toggle("hidden");
    const features = claimPrintVoucher.querySelector(".voucher__redeem-features--mobile");
    if (features) features.classList.toggle("hidden");
  }

}

function ValidateVoucher(container) {

  const validateVoucherForm = container.querySelector("form");
  if (validateVoucherForm) {
    initFormValidation(validateVoucherForm, "validateVoucher", handleFormData);
  }

  subscribe("validateVoucher:formSubmitted", () => {
    const messageContainer = container.querySelector(".form__message");
    messageContainer.classList.add("hidden");
  });

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }

    const { success, fields } = data;

    if (!success) {
      showErrorMessage(form, "Vi kunde inte verifiera din värdekod. Försök igen eller kontakta <a href='https://konto.bonniernews.se/kontakt/dn/'>kundservice.</a>");
    } else if (fields && fields.type === "digital") {
      publish("validateVoucher:showDigital", fields);
    } else if (fields && fields.type === "print") {
      publish("validateVoucher:showPrint", fields);
    }

    enableSubmitButton(form);
    return;
  }
}

function ClaimDigitalVoucher(container) {

  const claimDigitalVoucherForm = container.querySelector("form");
  if (claimDigitalVoucherForm) {
    initFormValidation(claimDigitalVoucherForm, "claimDigitalVoucherForm", handleFormData);
  }

  subscribe("ClaimDigitalVoucher:formSubmitted", () => {
    const messageContainer = container.querySelector(".form__message");
    messageContainer.classList.add("hidden");
  });

  function handleFormData(form, data) {
    if (data.errors) {
      enableSubmitButton(form);
      return;
    }
    if (!data.success) {
      showErrorMessage(form, data.errorHtml);
      enableSubmitButton(form);
      return;
    }

    if (data.success) {
      const currentUrl = window.location.href.replace(window.location.search, "");
      const next = data.loginLink || `${currentUrl}klart/`;
      campaignStepTracking(container, { stepName: "Confirmation", stepNumber: 2});
      window.location.replace(next);
      return;
    }
  }
}
